import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import robotoLightLink from '../../../fonts/roboto-v20-latin-ext/roboto-v20-latin-ext-300.woff2';
import robotoRegularLink from '../../../fonts/roboto-v20-latin-ext/roboto-v20-latin-ext-regular.woff2';
import robotoBoldLink from '../../../fonts/roboto-v20-latin-ext/roboto-v20-latin-ext-500.woff2';

export function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const metas = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
  ].concat(meta);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: 'antialiased',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metas}
    >
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        crossorigin="anonymous"
        href={robotoLightLink}
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        crossorigin="anonymous"
        href={robotoRegularLink}
      />
      <link
        rel="preload"
        as="font"
        type="font/woff2"
        crossorigin="anonymous"
        href={robotoBoldLink}
      />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'cs-cz',
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  suppressRobots: PropTypes.bool,
};
